<template>
    <div v-loading="isLoading" class="project">
        <header class="title">
            <i class="el-icon-arrow-left" @click="back" />
            项目列表
        </header>
        <div v-if="data.length > 0" class="project-list">
            <div v-for="(item, i) in data" :key="i" class="project" @click="handleProject(item)">
                <div class="project-img">
                    {{ item.state == 1 ? '进行中' : '未开始' }}（订单号：{{ item.id }}）
                </div>
                <div class="project-name">
                    <span>{{ item.productName }}</span>
                    <span>进入</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { productList } from "@/api/workfow.js";
export default {
    data() {
        return {
            isLoading: false,
            data: []
        }
    },
    created() {
        this.productList()
        this.$store.commit("user")
        this.$store.commit("setType")
    },
    methods: {
        async productList() {
            try {
                this.isLoading = true
                const { code, data } = await productList()
                if (code === 200) {
                    this.data = data || []
                }
            } finally {
                this.isLoading = false
            }
        },
        back() {
            this.$router.push({
                path: '/MobileManuscript',
                query: this.$route.query
            })
        },
        handleProject(data) {
            //登陆列表页-改变orderId
            let user = JSON.parse(localStorage.getItem("user"))
            user.orderId = data.id
            user.baseSchedule = data.baseSchedule
            user.hasFinishOnePro = data.step
            user.order_sn = data.order_sn
            localStorage.setItem("user", JSON.stringify(user))
            this.$router.push({
                path: '/MobileManuscript',
                query: {
                    userId: this.$route.query.userId,
                    orderId: data.id,
                    sign: this.$route.query.sign,
                }
            })
        }
    }
}
</script>

<style lang="less" scoped>
.project {
    height: 100%;
    background: #fff;
    .title {
        text-align: center;
        font-size: 16px;
        padding: 12px 0;
        position: fixed;
        border-bottom: 1px solid #f2f2f2;
        background: #fff;
        z-index: 1;
    }
    .el-icon-arrow-left {
        position: absolute;
        left: 15px;
        font-size: 20px;
        top: 50%;
        margin-top: -9px;
    }
    .project-list {
        padding: 46px 30px 20px;
        .project {
            border: 1px solid #eee;
            border-radius: 5px;
            overflow: hidden;
            margin-top: 15px;
        }
        .project-name {
            padding: 10px 15px;
            display: flex;
            span {
                font-size: 14px;
            }
            span:nth-child(1) {
                flex: 1;
            }
            span:nth-child(2) {
                color: #409eff;
            }
        }
        .project-img {
            height: 200px;
            overflow: hidden;
            background: url('../../../assets/img/card_bg.png') center no-repeat;
            background-size: 100%;
            color: #fff;
            padding: 10px 20px;
            font-size: 13px;
        }
    }
}
</style>
